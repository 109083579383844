// import qs from 'qs'
import router from '@/router'
// import http from '@/utils/http.js'
import store from '@/store'
import { getToken } from '@/utils/auth'
// import { setToken } from '@/utils/auth'

// 设置APPID
const whiteList = ['/login']
router.beforeEach(async (to, from, next) => {
  // 在白名单，直接进入
  if (whiteList.indexOf(to.path) !== -1) {
    return next()
  }

  if (!getToken()) {
    return next({
      path: "/login",
      query: { redirect: to.fullPath }
    })
  } else {
    await store.dispatch('user/getInfo')
    return next()
  }

})