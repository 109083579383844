export function getToken() {
  return localStorage.getItem('x-auth-token')
}

export function setToken(token) {
  return localStorage.setItem('x-auth-token', token)
}

export function setSite(site) {
  return localStorage.setItem('x-auth-site', site)
}

export function removeToken() {
  return localStorage.clear()
}

const siteConfig = {
  'default': {
    site: 'default',
    companyName: '苏州众和'
  },
  'nbzhz.h5.hk.zhongheinfo.com': {
    site: 'nbzhz',
    companyName: '宁波众致'
  }
}

export function getSite() {
  if (siteConfig[location.host]) {
    return siteConfig[location.host]
  } else {
    return siteConfig['default']
  }
}