import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Index' }
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登陆'},
    component: () => import('@/views/login')
  },
  {
    path: '/index',
    name: 'Index',
    meta: { title: '汇总统计'},
    component: () => import('@/views/index/index')
  },
  {
    path: '/user',
    name: 'User',
    redirect: { name: 'UserList' },
    component: () => import('@/views/user/index'),
    children: [
      {
        path: 'list',
        name: 'UserList',
        meta: { title: '推广统计'},
        component: () => import('@/views/user/list/index'),
      }
    ]
  },
  {
    path: '/media',
    name: 'Media',
    redirect: { name: 'MediaList' },
    component: () => import('@/views/media/index'),
    children: [
      {
        path: 'list',
        name: 'MediaList',
        meta: { title: '动态统计'},
        component: () => import('@/views/media/list/index'),
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
