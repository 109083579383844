import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

import './registerServiceWorker'

// 全局引入按需引入UI库 vant
// import '@/plugins/vant'

import http from '@/utils/http'
Vue.prototype.$axios = http

import moment from 'moment'
Vue.prototype.$moment = moment;

import base64 from 'js-base64'
Vue.prototype.$Base64 = base64.Base64;

// 授权
import '@/permission'

import './registerServiceWorker'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
