import axios from 'axios'
// import store from '@/store'
import { Notify } from 'vant'
import { getToken, removeToken, getSite } from '@/utils/auth'
// import wechatAuth from '../utils/wechatAuth'
// import qs from 'qs'

axios.defaults.timeout = 5000
axios.defaults.baseURL = 'https://api.hk.zhongheinfo.com'

axios.interceptors.request.use(
  config => {
    config.headers['site'] = getSite().site
    config.headers['token'] = getToken()
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  response => {
    if (response.data.code === 602) {
      removeToken()
      location.reload()
    }else{
      return response.data
    }

    
  },
  error => {
    Notify({ type: 'danger', message: error.msg });
    return Promise.reject(error)
  }
)
export default axios
