<template>
  <router-view />
</template>
<script>
// import wx from 'weixin-js-sdk'
export default {
  mounted () {
    // this.getJssdk()
  },
  methods: {
    // async getJssdk () {
    //   await this.$axios.post('/wxc/wx/js', {
    //     url: 'https://wechat.hk.zhongheinfo.com/',
    //     api_list: ['updateAppMessageShareData', 'updateTimelineShareData', 'chooseWXPay']
    //   }).then(res => {
    //     console.log(res.data)
    //     wx.config(res.data)
    //   })
    // }
  }
}
</script>
<style lang="scss">
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  outline: none;
}
body {
  color: #333;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  padding: 0;
  background-color: #f7f8fa;
}

a {
  text-decoration: none;
  color: #409eff;
}
img {
  max-width: 100%;
  vertical-align: middle;
}
::-webkit-scrollbar {
  display: none;
}
[class*=' van-icon-jue-'],
[class^='van-icon-jue-'] {
  font-family: 'iconfont' !important;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.app-container {
  max-width: 670px;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 15px;
  background-color: #f7f8fa;
  .van-sticky--fixed {
    background: #fff;
    padding: 0 15px;
  }
}

/* .block {
  margin-bottom: 15px;
} */

.block:first-child {
  margin-top: 15px;
}

.cell {
  padding: 10px 16px;
  background: #fff;
}
.cell .cell-title {
  font-weight: 600;
}
.no-data {
  padding: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.55);
}
.jue-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  
  th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    background: #fafafa;
    word-break: keep-all;
    white-space: nowrap;
  }
  td {
    color: #606266;
  }
  td,
  th {
    border-bottom: 1px solid #dfe6ec;
    padding: 2px 10px;
    line-height: 23px;
    font-size: 12px;
    text-align: center;
    vertical-align: middle !important;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
  }
  tr:hover td {
    background-color: #f5f7fa;
  }
}
</style>
