import axios from '@/utils/http'
import { getToken } from '@/utils/auth'
// import { getToken, setToken, setSite, removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    from_id: '',
    cache: {}
  }
}
const state = getDefaultState()
const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_FROM_ID: (state, from_id) => {
    state.from_id = from_id
  },
  SET_CACHE: (state, data) => {
    state.cache = data
  }
}


const actions = {
  getCache ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/index/cache_data').then(res => {
        console.log(res)
        commit('SET_CACHE', res.data)
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/user/detail').then(response => {
        const { data } = response

        if (!data) {
          return reject('Verification failed, please Login again.')
        }

        const { realname } = data

        commit('SET_NAME', realname)
        // commit('SET_AVATAR', avatar)
        // commit('SET_AVATAR', 'https://img13.360buyimg.com/ddimg/jfs/t1/146476/35/18667/17295/5fdad31dEc4a7b978/37fd5a9b827e8434.png')
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  }


  // 登录相关，通过code获取token和用户信息，用户根据自己的需求对接后台
  // loginWechatAuth ({ commit }, code) {
  //   const data = {
  //     code: code
  //   }
  //   return new Promise((resolve, reject) => {
  //     http.post('/wechat/auth2', qs.stringify(data)).then(res => {
  //       console.log(res)
  //       // 存用户信息，token
  //       // commit('SET_USERINFO', saveUserInfo(res.data.user))
  //       commit('SET_UNIONID', res.data)
  //       setUnionId(res.data)
  //       resolve(res)
  //     })
  //       .catch(error => {
  //         reject(error)
  //       })
  //   })
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
